import React from 'react';
import './App.css';
import MultiStepForm from './MultiStepForm';
import { StepOne, StepTwo, StepThree } from './steps';


export default function Vercel() {
    return (
        <div className="container">
      <div className="gradient-box">
        <h1 className="logo">NEXT.JS</h1>
      </div>
      <div className="content">
        <h2>Get started by editing <code>app/page.tsx</code></h2>
        <div className="grid">
          <a href="https://nextjs.org/docs" className="card">
            <h2>Continue &rarr;</h2>
            {/* <p>Find in-depth information about Next.js features and API.</p> */}
          </a>
          <a href="https://nextjs.org/learn" className="card">
            <h2>Learn &rarr;</h2>
            <p>Learn about Next.js in an interactive course with quizzes!</p>
          </a>
          <a href="https://nextjs.org/templates" className="card">
            <h2>Templates &rarr;</h2>
            <p>Explore starter templates for Next.js.</p>
          </a>
          <a href="https://vercel.com/new" className="card">
            <h2>Deploy &rarr;</h2>
            <p>Instantly deploy your Next.js site to a shareable URL with Vercel.</p>
          </a>
        </div>
        <button>Go to Onboard</button>
      </div>
    </div>
    //   <main className="flex min-h-screen flex-col items-center justify-between p-24">
    //     <div className="z-10 w-full max-w-5xl items-center justify-between font-mono text-sm lg:flex">
    //       <p className="fixed left-0 top-0 flex w-full justify-center border-b border-gray-300 bg-gradient-to-b from-zinc-200 pb-6 pt-8 backdrop-blur-2xl dark:border-neutral-800 dark:bg-zinc-800/30 dark:from-inherit lg:static lg:w-auto  lg:rounded-xl lg:border lg:bg-gray-200 lg:p-4 lg:dark:bg-zinc-800/30">
    //         Get started by editing&nbsp;
    //         <code className="font-mono font-bold">app/page.tsx</code>
    //       </p>
    //       <div className="fixed bottom-0 left-0 flex h-48 w-full items-end justify-center bg-gradient-to-t from-white via-white dark:from-black dark:via-black lg:static lg:size-auto lg:bg-none">
    //         <a
    //           className="pointer-events-none flex place-items-center gap-2 p-8 lg:pointer-events-auto lg:p-0"
    //           href="https://vercel.com?utm_source=create-next-app&utm_medium=appdir-template&utm_campaign=create-next-app"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //         >
    //           By{" "}
            
    //         </a>
    //       </div>
    //     </div>

    //     <div className="center-container">
    //     <div className="gradient-box">
    //         <img src={require("./next.svg")} width={180} height={37} alt='next logo'></img>
          
    //         </div>
    //     </div>

  
    //     <div className="mb-32 grid text-center lg:mb-0 lg:w-full lg:max-w-5xl lg:grid-cols-4 lg:text-left">
    //       <a
    //         href="https://nextjs.org/docs?utm_source=create-next-app&utm_medium=appdir-template&utm_campaign=create-next-app"
    //         className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-gray-300 hover:bg-gray-100 hover:dark:border-neutral-700 hover:dark:bg-neutral-800/30"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         <h2 className="mb-3 text-2xl font-semibold">
    //           Docs{" "}
    //           <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">
    //             -&gt;
    //           </span>
    //         </h2>
    //         <p className="m-0 max-w-[30ch] text-sm opacity-50">
    //           Find in-depth information about Next.js features and API.
    //         </p>
    //       </a>
  
    //       <a
    //         href="https://nextjs.org/learn?utm_source=create-next-app&utm_medium=appdir-template-tw&utm_campaign=create-next-app"
    //         className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-gray-300 hover:bg-gray-100 hover:dark:border-neutral-700 hover:dark:bg-neutral-800/30"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         <h2 className="mb-3 text-2xl font-semibold">
    //           Learn{" "}
    //           <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">
    //             -&gt;
    //           </span>
    //         </h2>
    //         <p className="m-0 max-w-[30ch] text-sm opacity-50">
    //           Learn about Next.js in an interactive course with&nbsp;quizzes!
    //         </p>
    //       </a>
  
    //       <a
    //         href="https://vercel.com/templates?framework=next.js&utm_source=create-next-app&utm_medium=appdir-template&utm_campaign=create-next-app"
    //         className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-gray-300 hover:bg-gray-100 hover:dark:border-neutral-700 hover:dark:bg-neutral-800/30"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         <h2 className="mb-3 text-2xl font-semibold">
    //           Templates{" "}
    //           <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">
    //             -&gt;
    //           </span>
    //         </h2>
    //         <p className="m-0 max-w-[30ch] text-sm opacity-50">
    //           Explore starter templates for Next.js.
    //         </p>
    //       </a>
  
    //       <a
    //         href="https://vercel.com/new?utm_source=create-next-app&utm_medium=appdir-template&utm_campaign=create-next-app"
    //         className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-gray-300 hover:bg-gray-100 hover:dark:border-neutral-700 hover:dark:bg-neutral-800/30"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         <h2 className="mb-3 text-2xl font-semibold">
    //           Deploy{" "}
    //           <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">
    //             -&gt;
    //           </span>
    //         </h2>
    //         <p className="m-0 max-w-[30ch] text-balance text-sm opacity-50">
    //           Instantly deploy your Next.js site to a shareable URL with Vercel.
    //         </p>
    //       </a>
    //     </div>
    //   </main>
    );
  }