import React from 'react';

const StepThree = ({ data, onDataChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onDataChange({ [name]: value });
  };

  return (
    <div>
      <h1>How much would you like to charge people in order to access your link?</h1>
      <p className="description">You can always change this later! (Please provide in USD)</p>
      <div className="form-group">
        {/* <label>Additional Info</label> */}
        <input
          type="number"
          name="amount"
          datatype=''
          value={data.amount || ''}
          onChange={handleChange}
          placeholder="$5.00"
          required
        />
      </div>
      {/* <div className="form-navigation">
        <button className="inactive">Back</button>
        <button type="submit">Submit</button>
      </div> */}
    </div>
  );
};

export default StepThree;