import React, { useState } from "react";

export default function Home() {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();

  return (
    <div id="root">
      <div className="banner">
      <div className="gradient-box">
        <h1 style={{fontSize: 45, fontStyle: 'italic'}}>Charge Stream</h1>
        {/* <img style={{ width: 100 }} src="https://firebasestorage.googleapis.com/v0/b/charge-stream.appspot.com/o/Logo%2Fcharge.stream.logo.png?alt=media" /> */}
        </div>
      </div>
      <div className="container">
     
        <div className="content">
          {!connectedAccountId && <h1>Turn any link into a business</h1>}
          {!connectedAccountId && <p>Add a paywall to any website, content, link in bio and start making money from your visitors instantly</p>}
          {connectedAccountId && <h2>Add information to start accepting money</h2>}
          {connectedAccountId && <p>Matt's Mats partners with Stripe to help you receive payments and keep your personal bank and details secure.</p>}
          {!accountCreatePending && !connectedAccountId && (
            <>
            <button style={{fontStyle: 'italic'}} onClick={() => {window.location.href = '/onboard';}}>
              Get Started for Free
              {accountCreatePending && <div className="spinner"></div>}
            </button>           
            <p style={{fontSize: 13, color: "gray", marginTop: 5}}>Entire process takes just 5 minutes 🚀</p>
            </>
          )}
          {accountCreatePending && (
            <button disabled>
              <div className="spinner"></div>
            </button>
          )}
        </div>
        </div>
    </div>
  );
}