import React from 'react';

const StepOneHalf = ({ data, onDataChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onDataChange({ [name]: value });
  };

  return (
    <div>
      <h1>Next, what is your email and phone number?</h1>
      <br></br>
      {/* <p className="description">(optional)</p> */}
      <div className="form-group">
        <label>Email *</label>
        <input
          type="email"
          name="email"
          value={data.email || ''}
          onChange={handleChange}
          placeholder="you@email.com"
          required
        />
      </div>      
      <div className="form-group">
        <label>Phone Number *</label>
        <input
          type="phone"
          name="phone"
          value={data.phone || ''}
          onChange={handleChange}
          placeholder="510-222-1234"
          required
        />
      </div>
    </div>
  );
};

export default StepOneHalf;