import React from 'react';

const StepTwo = ({ data, onDataChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onDataChange({ [name]: value });
  };

  return (
    <div>
      <h1>Next, what link would you like to monetize?</h1>
      <p className="description">We will create a charge.stream link that redirects to this provided link once a viewer pays!</p>
      <div className="form-group">
        {/* <label>Additional Info</label> */}
        <input
          type="url"
          name="link"
          datatype='url'
          value={data.link || ''}
          onChange={handleChange}
          placeholder="yourlink.com"
          required
        />
      </div>
      {/* <div className="form-navigation">
        <button className="inactive">Back</button>
        <button type="submit">Submit</button>
      </div> */}
    </div>
  );
};

export default StepTwo;