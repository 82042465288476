import React from 'react';
import './App.css';
import MultiStepForm from './MultiStepForm';
import { StepOne, StepTwo, StepThree, StepOneHalf } from './steps';

function App() {
  const steps = [StepOne, StepOneHalf, StepTwo, StepThree]; // Add more steps as needed

  return (
    <div className="App">
      <MultiStepForm steps={steps} />
    </div>
  );
}

export default App;

// import React, { useState } from 'react';
// import './Onboard.css';

// const Onboard = () => {
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(formData);
//     // Add your form submission logic here
//   };

//   return (
//     <div className="form-container">
//       <h1>First things first, what's your name and email</h1>
//       <br></br>
//       {/* <p className="description">(optional)</p> */}
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label>First Name *</label>
//           <input
//             type="text"
//             name="firstName"
//             value={formData.firstName}
//             onChange={handleChange}
//             placeholder="Jane"
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Last Name *</label>
//           <input
//             type="text"
//             name="lastName"
//             value={formData.lastName}
//             onChange={handleChange}
//             placeholder="Smith"
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Email *</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             placeholder="name@example.com"
//             required
//           />
//         </div>
//         <button type="submit">Continue</button>
//       </form>
//     </div>
//   );
// };

// export default Onboard;