import React, { useState, useEffect, useRef } from 'react';
import './MultiStepForm.css';

const MultiStepForm = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [isNextActive, setIsNextActive] = useState(false);
  const [direction, setDirection] = useState(null);
  const nextButtonRef = useRef(null);

  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();

  useEffect(() => {
    validateStep();
  }, [formData, currentStep]);

  const handleSubmit = () => {
    console.log('Form submitted', formData);
    setAccountCreatePending(true);
    handleAccountCreation();
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // Prevent default form submission
        if (isNextActive && nextButtonRef.current) {
          nextButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isNextActive]);

  const handleAccountCreation = async () => {
    setError(false);
    try {
      console.log("this is formdata: ", formData);
      const response = await fetch("https://charge-stream-server.vercel.app/account", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone
          // dob: { day: 1, month: 1, year: 1990 },
          // address: {
          //   line1: "123 Main St",
          //   city: "Anytown",
          //   state: "CA",
          //   postalCode: "12345",
          //   country: "US",
          // }
        }),
      });
      const json = await response.json();
      // setAccountCreatePending(false);

      const { account, error } = json;

      if (account) {
        setConnectedAccountId(account);
        handleAccountLinkCreation(account);  // Call the second function here
      }

      if (error) {
        setError(true);
      }
    } catch (err) {
      setAccountCreatePending(false);
      setError(true);
    }
  };

  const handleAccountLinkCreation = async (accountId) => {
    setAccountLinkCreatePending(true);
    setError(false);
    try {
      const response = await fetch("https://charge-stream-server.vercel.app/account_link", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          account: accountId,
        }),
      });
      const json = await response.json();
      setAccountLinkCreatePending(false);
      setAccountCreatePending(false);

      const { url, error } = json;
      if (url) {
        window.location.href = url;
      }

      if (error) {
        setError(true);
      }
    } catch (err) {
      setAccountLinkCreatePending(false);
      setAccountCreatePending(false);
      setError(true);
    }
  };

  const handleNext = () => {
    if (isNextActive && currentStep < steps.length - 1) {
      setDirection('next');
      setTimeout(() => {
        setCurrentStep(currentStep + 1);
        setDirection(null);
      }, 300);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setDirection('prev');
      setTimeout(() => {
        setCurrentStep(currentStep - 1);
        setDirection(null);
      }, 300);
    }
  };

  const handleDataChange = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const validateStep = () => {
    const currentData = formData;
    let isValid = true;

    if (currentStep === 0) {
      isValid = !!(currentData.firstName && currentData.lastName);
    } else if (currentStep === 1) {
      isValid = !!(currentData.email && currentData.phone);
    } else if (currentStep === 2) {
      isValid = !!currentData.link;
    }

    setIsNextActive(isValid);
  };

  const CurrentStepComponent = steps[currentStep];

  return (
    <div className="multi-step-form">
      <div className={`form-container ${direction}`}>
        <CurrentStepComponent
          data={formData}
          onDataChange={handleDataChange}
        />
        <div className="form-navigation">
          {currentStep > 0 && <button onClick={handlePrevious} className="back-button">Back</button>}
          {currentStep < steps.length - 1 ? (
            <button
              onClick={handleNext}
              className={`next-button ${isNextActive ? '' : 'inactive'}`}
              disabled={!isNextActive}
              ref={nextButtonRef}
            >
              Continue
            </button>
          ) : (
            accountCreatePending ? <button>
            <div className="spinner"></div>
            </button> : <button
              onClick={handleSubmit}
              className={`next-button ${isNextActive ? '' : 'inactive'}`}
              disabled={!isNextActive}
              ref={nextButtonRef}
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiStepForm;